<template>
  <view-container name="physiological-data">
    <view-header>
      <custom-header :router-back="'/settings'" class="sub-page-settings__title">
        <template v-slot:default>
          <svg-icon size="size20x20" :name="'icoArrowLeftBlue'" />
          Back
        </template>
        <template #last>
          <button class="btn-icon-t1 physiological-data__save" @click="handleUserUpdate">
            <svg-icon size="size20x20" :name="'icoSave'" />
            Save
          </button>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div data-vue-name="PhysiologicalData" class="physiological-data layout-grid-2row-revers">
        <div class="container-fluid">
          <div class="physiological-data__title sub-page-title-settings">
            Physiological Data
          </div>
          <form class="google-style-input">
            <div class="group">
              <label class="label--focused required-input">Metric</label>
              <ul v-if="metric" class="radio-btn-box">
                <li v-for="(item, ind) in metrics" :key="ind">
                  <input type="radio" v-model="metric" name="Metric" :value="item" :id="item + ind" />
                  <label :for="item + ind">{{ item }}</label>
                </li>
              </ul>
            </div>

            <div class="group wrap-2-col">
              <input type="number" min='0' max='250' name="full-name" required v-model.number="info.height" />
              <p class="metric m-3">{{ heightByMetrics }}</p>
              <label class="required-input">Height</label>
              <span v-if="errors.height" class="danger google-style-input__description">
                {{ errors.height[0] }}
              </span>
            </div>
            <div class="group wrap-2-col">
              <input type="number" min='0' max='200' name="full-name" required v-model.number="info.weight" />
              <p class="metric m-3">{{ weightByMetrics }}</p>
              <label class="required-input">Weight</label>
              <span v-if="errors.weight" class="danger google-style-input__description">
                {{ errors.weight[0] }}
              </span>
            </div>
            <div class="group wrap-2-col">
              <label class="label--focused required-input">Gender</label>
              <a-select class="gender-select" required v-model="gender">
                <a-select-option v-for="(gender, ind) in genders" :key="ind">
                  {{ gender }}
                </a-select-option>
              </a-select>
              <span v-if="errors.gender" class="danger google-style-input__description">
                {{ errors.gender[0] }}
              </span>
            </div>
            <div class="group">
              <label class="required-input label--focused">Sports experience</label>
              <ul class="radio-btn-box">
                <li>
                  <input type="radio" v-model="experience" name="sportExp" id="sportExp1" value="5" />
                  <label for="sportExp1">Less than 5 years</label>
                </li>
                <li>
                  <input type="radio" v-model="experience" name="sportExp" id="sportExp2" value="6" />
                  <label for="sportExp2">5 – 10 years</label>
                </li>
                <li>
                  <input type="radio" v-model="experience" name="sportExp" id="sportExp3" value="10"
                    :disabled="disabledSportExp3" />
                  <label for="sportExp3" :class="{ 'is-disabled': disabledSportExp3 }">More than 10 years</label>
                </li>
              </ul>
              <span v-if="errors.experience" class="danger google-style-input__description">
                {{ errors.experience[0] }}
              </span>
            </div>
          </form>
        </div>
      </div>
    </view-content>
  </view-container>
</template>
<script>
import { mapState } from 'vuex'
import { getIdFromRedirect } from "@/api/public/event"

export default {
  name: 'PhysiologicalData',
  components: {},
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
      user: state => state.auth.user,
      invitationId: ({ eventHash }) => eventHash.id,
      invitationType: ({ eventHash }) => eventHash.type,
    }),
    experience: {
      get() {
        const experienceDefault = this.$store.state.auth.user?.experience
        this.setDefaultExperience(experienceDefault);
        return experienceDefault;

      },
      set(value) {
        this.info.experience = value
      },
    },
    metric: {
      get() {
        const metricDefault = this.$store.state.auth.user?.metric
        this.setDefaultMetric(metricDefault);
        return metricDefault
      },
      set(value) {
        this.info.metric = value
        this.$store.commit('setMetric', value)
      },
    },
    gender: {
      get() {
        const genderDefault = this.$store.state.auth.user?.gender
        this.setDefaultGender(genderDefault);
        return genderDefault
      },
      set(value) {
        this.info.gender = value;
        this.$store.commit('setGender', value)
      },
    },
    disabledSportExp3() {
      const birthday = new Date(this.$store.state.auth.user?.birthday).getFullYear();
      const dateNow = new Date().getFullYear();
      return (dateNow - birthday > 10) ? false : true;
    },
    heightByMetrics() {
      return this.$store.getters.valueByMetrics?.height
    },
    weightByMetrics() {
      return this.$store.getters.valueByMetrics?.weight
    },
  },
  data() {
    return {
      genders: {
        man: "Male",
        woman: "Female"
      },
      metrics: ['American', 'European'],
      info: {
        height: '',
        weight: '',
        experience: '',
        metric: '',
        gender: '',
      },
      idRedirect: null
    }
  },
  watch: {
    user: {
      handler(user) {
        if (!user) {
          return;
        }
        // this.info.height = user.height ? user.height : ''
        // this.info.weight = user.weight ? user.weight : ''
      },
      deep: true
    },
    'info.weight'(value, old) {
      value = String(value)
      if (value.split('').filter(item => item === '.').length > 1) {
        this.info.weight = old
        return;
      }
      let parts = value.split('.')
      if (parts[1] && parts[1].split('').length > 1) {
        this.info.weight = old
        return;
      }
      let format = null;
      if (this.$store.getters.valueByMetrics.weight === "Kg") {
        format = value > 200 ? 200 : value < 1 ? '' : value
      }
      if (this.$store.getters.valueByMetrics.weight === "lb") {
        format = value > 250 ? 250 : value < 1 ? '' : value
      }
      if (format !== '') {
        format = Number(String(format).replace(',', ''))
      }
      this.info.weight = format

    },
    'info.height'(value, old) {
      value = String(value)
      if (value.split('').filter(item => item === '.').length > 1) {
        this.info.height = old
        return;
      }
      let parts = value.split('.')
      if (parts[1] && parts[1].split('').length > 1) {
        this.info.height = old
        return;
      }
      let format = null;
      if (this.$store.getters.valueByMetrics.height === "Cm") {
        format = value > 250 ? 250 : value < 1 ? '' : value
      }
      if (this.$store.getters.valueByMetrics.height === "ft") {
        format = value > 9 ? 9 : value < 1 ? '' : value
      }
      if (format !== '') {
        format = Number(String(format).replace(',', ''))
      }
      this.info.height = format
    }
  },
  created() {
    this.getRedirectedId()
  },
  mounted() {
    if (!this.user) {
      return;
    }
    this.info.height = this.user.height ? this.user.height : ''
    this.info.weight = this.user.weight ? this.user.weight : ''
  },
  methods: {
    setDefaultMetric(value) {
      this.info.metric = value;
    },
    setDefaultExperience(value) {
      this.info.experience = value;
    },
    setDefaultGender(value) {
      this.info.gender = value;
    },
    handleUserUpdate() {
      this.$store.dispatch('updateUser', this.info).then(() => {
        if (this.invitationId) {
          if (this.invitationType === 'event') {
            this.$router.push(`/details/${this.idRedirect}`)
          }
          if (this.invitationType === 'tournament-event') {
            this.$router.push(`/details-event/${this.idRedirect}`)
          }
        } else {
          this.$router.push('settings')
        }
      })
    },
    async getRedirectedId() {
      if (this.invitationId) {
        try {
          const { data } = await getIdFromRedirect(this.invitationId, this.invitationType)
          this.idRedirect = data.id
        } catch (e) {
          console.error(e)
        }
      }
    }
  },
}
</script>
<style lang="stylus">

.physiological-data-view {
  .view-content {
    padding: 0 16px !important
  }

  .container-fluid {
    padding: 0
  }
}

.physiological-data .metric {
  text-transform: lowercase
}
.physiological-data .danger {
  color: red !important;
}
.physiological-data__save {
  font-size: 16px !important;
  line-height: 22px !important;
  color: #4285f4 !important;
}

.radio-btn-box .is-disabled{
  opacity: .5;
}
</style>
